import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {useDialogCrup} from '@iamsoftware/react-hooks';

import {NftCategoriesService} from '../../service/common/NftCategoriesService';
import {NftAssetsService} from '../../service/common/NftAssetsService';

import {usePolkadot} from '../../shared/substrate-lib/Polkadot';

import {Breadcrumb} from '../components/layout/Breadcrumb';
import {ProductBetaMd} from '../components/Product';
import {Pagination} from '../components/Pagination';

export const Accounts = ({iamUser, balances}) => {

  const {signedTx} = usePolkadot();
  const [txStatus, setTxStatus] = useState(null);

  const [categories, setCategories] = useState([]);

  const [search, setSearch] = useState('');
  const [items, setItems] = useState([]);

  useEffect(() => {
    const event = {
      filters: {
        image: {value: 'mb-cat', matchMode: 'equals'}
      }
    };
    NftCategoriesService.getList(JSON.stringify(event)).then(data => {
      setCategories(data.items.map(item => {
        return {value: item._id, label: item.name}
      }));
    });
  }, []);

  useEffect(() => {
    load();
  }, [iamUser, search]); // eslint-disable-line react-hooks/exhaustive-deps

  const load = () => {
    const event = {
      filters: {
        user: {value: iamUser._id, matchMode: 'ObjectId'},
        status: {value: 'Draft', matchMode: 'notEquals'}
      }
    };
    if (search) {
      event.filters['name'] = {value: search, matchMode: 'contains'};
    }
    NftAssetsService.getList(JSON.stringify(event)).then(data => {
      setItems(data?.items);
    });
  }

  const {render: renderDialogCreate, create, update} = useDialogCrup({
    header: 'NFT',
    dataKey: '_id',
    width: '60rem',
    fields: [
      {field: 'name', header: 'Account No', required: true, className: 'md:col-4'},
      {field: 'description', header: 'Account Name', className: 'md:col-8'},
      {field: 'short_description', header: 'Short Description', type: 'InputTextarea', className: 'md:col-12'},
      {field: 'long_description', header: 'Long Description', type: 'InputTextarea', className: 'md:col-12'},
      {
        field: 'nftCategory', header: 'Category', required: true, type: 'Dropdown', DropdownProps: {
          options: categories
        }, className: 'md:col-12'
      },
      {field: 'image', header: 'Background Image', type: 'ImageUploader', ImageUploaderProps: {width: 200, height: 200, encoding: 'base64'}, className: 'md:col-12'},
      {type: 'custom', className: 'md:col-12', body: <b>{txStatus}</b>}
    ],
    createItem: item => {

      setTxStatus(null);

      item.user = iamUser._id;
      item.status = 'Draft';

      return NftAssetsService.create(item)
        .then(doc => {
          if (doc?.key) {
            return new Promise<void>(resolve => {
              const setResult = result => {
                if (result) {
                  if (result.status) {
                    setTxStatus(result.status);
                  }
                  if (result.isFinalized) {
                    resolve();
                  }
                  if (result.blockHash) {
                    NftAssetsService.update(doc._id, {block_hash: result.blockHash, status: 'Open'}).then(() => {
                      resolve();
                    });
                  }
                }
              }
              signedTx('nftwcnModule', 'createNft', [doc.key], setResult);
            });
          }
        });
    },
    updateItem(id: string, item: any): Promise<any> {
      return NftAssetsService.update(id, item);
    },
    reloadLazyData: load
  });

  const doUpdate = item => {
    if (item.nftCategory) {
      item.nftCategory = item.nftCategory._id;
    }
    update(item);
  }

  return (
    <Fragment>
      <Breadcrumb title="Accounts"/>

      <div className="auction-wrapper pt-50 pb-110">
        <div className="container">
          <div className="row g-4">
            <div className="col-7 p-fluid">
              <span className="p-input-icon-left">
                <i className="pi pi-search"/>
                <InputText value={search} onChange={e => setSearch(e.target.value)} placeholder="Search"/>
              </span>
            </div>
            <div className="col-5 text-right">
              {iamUser.type === 'bank' && <Button label="Public New Account" icon="pi pi-plus" className="p-button-success p-button-sm" onClick={create}/>}
              {renderDialogCreate()}
            </div>
            {items.map((item, index) => <div key={index} className="col-lg-4 col-md-6">
              <ProductBetaMd iamUser={iamUser} balances={balances} asset={item} fontSize="3rem" doUpdate={doUpdate}/>
            </div>)}
          </div>

          <Pagination/>
        </div>
      </div>
    </Fragment>
  );
}