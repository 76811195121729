export const Pagination = () => {

  const someValidPath = () => {
    return undefined;
  }

  return (
    <ul className="pagination pagination-style-one justify-content-center pt-50">
      <li className="page-item page-arrow"><a className="page-link" href={someValidPath()}> <i className="bi bi-arrow-left"></i></a></li>
      <li className="page-item active"><a className="page-link" href={someValidPath()}>01</a></li>
      <li className="page-item"><a className="page-link" href={someValidPath()}>02</a></li>
      <li className="page-item"><a className="page-link" href={someValidPath()}>03</a></li>
      <li className="page-item"><a className="page-link" href={someValidPath()}>04</a></li>
      <li className="page-item page-arrow"><a className="page-link" href={someValidPath()}> <i className="bi bi-arrow-right"></i></a></li>
    </ul>
  );
}