import {Link} from 'react-router-dom';

export const Footer = () => {

  const someValidPath = () => {
    return undefined;
  }

  return (
    <footer className="footer-area footer-style-two">
      <div className="container">
        <div className="footer-main">
          <div className="row d-flex justify-content-center gy-5">
            <div className="col-lg-3 col-md-6 col-sm-10 col-11">
              <div className="footer-widget">
                <div className="footer-disc">
                  <a href={someValidPath()}>
                    <div style={{fontWeight: 400, fontFamily: "'Audiowide', cursive", fontSize: '4rem', color: 'white'}}>Whychain</div>
                  </a>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                </div>
                <div className="footer-social-wrap">
                  <h5 className="widget-title d-inline-block">Follow Me</h5>
                  <ul className="footer-social-links justify-content-start">
                    <li><a href="https://www.facebook.com"><i className="bx bxl-facebook"></i></a></li>
                    <li><a href="https://twitter.com"><i className="bx bxl-twitter"></i></a></li>
                    <li><a href="https://www.pinterest.com"><i className="bx bxl-pinterest"></i></a></li>
                    <li><a href="https://www.instagram.com"><i className="bx bxl-instagram"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-10 col-11 d-flex justify-content-lg-center justify-content-start">
              <div className="footer-widget">
                <h5 className="widget-title">Quick Links</h5>
                <ul className="widget-links">
                  <li><Link to="/pages/login">My Account</Link></li>
                  <li><a href={someValidPath()}>Affiliate Program</a></li>
                  <li><a href={someValidPath()}>Lawyer Consulting</a></li>
                  <li><a href={someValidPath()}>Sorteo Locemses</a></li>
                  <li><a href={someValidPath()}>Privacey Policy</a></li>
                  <li><a href={someValidPath()}>Term &amp; Condition</a></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-10 col-11 d-flex justify-content-lg-center justify-content-start">
              <div className="footer-widget">
                <h5 className="widget-title">Help Center</h5>
                <ul className="widget-links">
                  <li><a href={someValidPath()}>Help Center</a></li>
                  <li><a href={someValidPath()}>FAQ</a></li>
                  <li><a href={someValidPath()}>Borrow</a></li>
                  <li><a href={someValidPath()}>License Agreement</a></li>
                  <li><a href={someValidPath()}>Sell your Product</a></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-10 col-11">
              <div className="footer-widget">
                <h5 className="widget-title">Contact Us</h5>
                <ul className="footer-contact-list">
                  <li>
                    <div className="contact-icon"><i className="bi bi-telephone-plus"></i></div>
                    <div className="contact-list">
                      <a href="tel:+84 776322555">+84 776 322 555</a>
                      <a href="tel:+84 776322555">+84 776 322 555</a>
                    </div>
                  </li>
                  <li>
                    <div className="contact-icon"><i className="bi bi-envelope"></i></div>
                    <div className="contact-list">
                      <a href="mailto:info@whychain.net">info@whychain.net</a>
                      <a href="mailto:support@whychain.net">support@whychain.net</a>
                    </div>
                  </li>
                  <li>
                    <div className="contact-icon"><i className="bi bi-geo-alt"></i></div>
                    <div className="contact-list">
                      <a href={someValidPath()}>123 Quang Trung, Ha Dong, Ha Noi, Vietnam</a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright row align-items-center">
          <div className="col-lg-6 col-md-6 order-1 order-md-0 text-center text-md-start mt-3 mt-md-0">
            <p>Copyright 2022 <a href={someValidPath()}>Whychain</a> | Design By <a href="https://whychain.net/" rel="noreferrer" target="_blank">Whychain Software Vietnam</a></p>
          </div>
          <div className="col-lg-6 col-md-6 order-0 order-md-1">
            <div className="acceptable-cards float-lg-end text-center text-md-start">
              <img src={'assets/images/icon/payment-cards.png'} alt="" className="img-fluid"/>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}