export const Sponsor = () => {

  return (
    <div className="sponsor-area sponsor-style-one pt-110 pb-110">
      <div className="container">
        <div className="row row-cols-xxl-6 row-cols-md-4 row-cols-sm-3 row-cols-2 g-3 d-flex justify-content-center">
          <div className="col">
            <div className="sponsor-logo">
              <img className="gray-img" src="assets/images/collection/sp1.png" alt=""/>
            </div>
          </div>
          <div className="col">
            <div className="sponsor-logo">
              <img src="assets/images/collection/sp2.png" alt=""/>
            </div>
          </div>
          <div className="col">
            <div className="sponsor-logo">
              <img src="assets/images/collection/sp3.png" alt=""/>
            </div>
          </div>
          <div className="col">
            <div className="sponsor-logo">
              <img src="assets/images/collection/sp4.png" alt=""/>
            </div>
          </div>
          <div className="col">
            <div className="sponsor-logo">
              <img src="assets/images/collection/sp3.png" alt=""/>
            </div>
          </div>
          <div className="col">
            <div className="sponsor-logo">
              <img src="assets/images/collection/sp4.png" alt=""/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}