import {useEffect, useState} from 'react';

import {NftCategoriesService} from '../../../service/common/NftCategoriesService';

export const Categories = () => {

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const event = {
      filters: {
        image: {value: 'mb-cat', matchMode: 'equals'}
      }
    };
    NftCategoriesService.getList(JSON.stringify(event)).then(data => {
      setCategories(data.items);
    });
  }, []);

  const someValidPath = () => {
    return undefined;
  }

  return (
    <div className="product-categorys-style-two mt-110">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-header-two text-center">
              <h3>Auction Product Category</h3>
            </div>
          </div>
        </div>
        <div className="row row-cols-xl-6 row-cols-lg-5 row-cols-md-4 row-cols-sm-3  row-cols-2 justify-content-center gy-4">
          {categories.map((item, index) => <div key={index} className="col">
            <div className="single-category">
              <div className="icon mx-auto ">
                <img src={'assets/images/icon/car-gray.svg'} alt="" className="icon1"/>
                <img src={'assets/images/icon/car-white.svg'} alt="" className="icon2"/>
                <div className="category-count"><span>30</span></div>
              </div>
              <h6>{item.name}</h6>
              <a href={someValidPath()} className="category-btn"><i className="bi bi-arrow-down"></i></a>
            </div>
          </div>)}

        </div>
      </div>
    </div>
  );
}