import axios from '../axios';

import {EntityService} from '../EntityService';

export class UserService extends EntityService {

  static entity = 'users';

  static getUsers(): Promise<any> {
    return axios.get(`${this.entity}`).then(res => res.data);
  }

}
