import {Fragment} from 'react';

import {Hero} from './components/Hero';
import {Categories} from './components/Categories';
import {LiveAuction} from './components/LiveAuction';
import {UpComingAuction} from './components/UpComingAuction';
import {StepsToWin} from './components/StepsToWin';
import {TopWinner} from './components/TopWinner';
import {WinnersSay} from './components/WinnersSay';
import {RecentNews} from './components/RecentNews';
import {Sponsor} from './components/Sponsor';
import {Newsletter} from './components/Newsletter';

export const Home = ({iamUser, balances}) => {

  return (
    <Fragment>
      <Hero iamUser={iamUser} balances={balances}/>
      <Categories/>
      <LiveAuction iamUser={iamUser} balances={balances}/>
      <UpComingAuction iamUser={iamUser} balances={balances}/>
      <StepsToWin/>
      <TopWinner/>
      {/*<WinnersSay/>*/}
      {/*<RecentNews/>*/}
      <Sponsor/>
      <Newsletter/>
    </Fragment>
  );
}