import {Fragment, useEffect, useState} from 'react';
import {Route, Routes} from 'react-router-dom';

import {UserService} from '../service/common/UserService';
import {AppStorage} from '../service/UserStorage';

import {SubstrateContextProvider, useSubstrateState} from '../shared/substrate-lib/SubstrateContext';
import {usePolkadot} from '../shared/substrate-lib/Polkadot';

import {Topbar} from './components/layout/Topbar';
import {Header} from './components/layout/Header';
import {Footer} from './components/layout/Footer';

import {Home} from './home';
import {Accounts} from './accounts';
import {AccountDetail} from './accounts/AccountDetail';
import {Holdings} from './holdings';
import {Pages} from './pages';

const Main = () => {

  const {apiState, apiError, keyringState} = useSubstrateState();

  const [iamUser, setIamUser] = useState(null);
  const [iamUsers, setIamUsers] = useState(null);

  useEffect(() => {
    loadIamUsers(true);
  }, []);

  useEffect(() => {
    if (iamUser?._id) {
      AppStorage.set('iamUser', iamUser?._id);
    }
  }, [iamUser?._id]);

  const loadIamUsers = (setDefaultUser?: boolean) => {
    UserService.getUsers().then(data => {

      const _iamUsers = data.filter(item => ['bank', 'mb-customer'].includes(item.type));

      setIamUsers(_iamUsers);
      if (setDefaultUser === true) {
        const _iamUser = AppStorage.get('iamUser') || _iamUsers[1]._id;
        _iamUsers.forEach(item => {
          if (item._id === _iamUser) {
            setIamUser(item);
          }
        });
      }
    });
  }

  const loader = text => (
    <div className="mt-5 text-center">
      <b>{text}</b>
    </div>
  );

  const message = errObj => (
    <div className="mt-5 text-center">
      <b>{`Connection to websocket ${errObj.target.url} failed.`}</b>
    </div>
  );

  if (apiState === 'ERROR') return message(apiError);
  else if (apiState !== 'READY') return loader('Connecting to Substrate');

  if (keyringState !== 'READY') {
    return loader(
      "Loading accounts (please review any extension's authorization)"
    );
  }

  return (
    <Fragment>
      <Topbar iamUsers={iamUsers} iamUser={iamUser} setIamUser={setIamUser}/>
      {iamUser && <Fragment>
				<Header/>
				<Body iamUser={iamUser}/>
				<Footer/>
			</Fragment>}
    </Fragment>
  );
}

const Body = ({iamUser}) => {

  const {setAccount, toString, query} = usePolkadot();

  const [balances, setBalances] = useState(null);

  useEffect(() => {
    if (iamUser?.address) {
      setAccount(iamUser.address);

      query('nftwcnModule', 'loyaltyOwned', [iamUser.address], result => {
        try {

          const currencies: any = [
            {value: 'USD', label: 'USD'},
            {value: 'EUR', label: 'EURO'},
            {value: 'VND', label: 'VND'}
          ];

          const loyalties = JSON.parse(result);
          loyalties.forEach(loyalty => {
            currencies.forEach(currency => {
              if (toString(loyalty.orgCode) === currency.value) {
                currency.availableBalance = loyalty.amount;
              }
            });
          });
          setBalances([...currencies]);
        } catch (e) {
        }
      });
    }
  }, [iamUser?.address]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Routes>
      <Route path="*" element={<Home iamUser={iamUser} balances={balances}/>}/>
      <Route path="accounts" element={<Accounts iamUser={iamUser} balances={balances}/>}/>
      <Route path="accounts/:id" element={<AccountDetail iamUser={iamUser} balances={balances}/>}/>
      <Route path="holdings" element={<Holdings iamUser={iamUser} balances={balances}/>}/>
      <Route path="pages/*" element={<Pages/>}/>
    </Routes>
  );
}

export const App = () => {
  return (
    <SubstrateContextProvider>
      <Main/>
    </SubstrateContextProvider>
  );
}