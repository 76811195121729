import {createRoot} from 'react-dom/client';

import {HashRouter} from 'react-router-dom';

import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import '@iamsoftware/react-hooks/assets/style.scss';
import './assets/bootstrap/css/bootstrap.min.css';
import './assets/css/style.css';
import './App.scss';

import {AppConfirmDialog, AppToast, DefaultConf, ScrollToTop} from '@iamsoftware/react-hooks';

import {App} from './app';

DefaultConf.DataTable.IndexColumnHeader = '#';
DefaultConf.DataTable.CurrentPageReportTemplate = 'Showing {first} to {last} of {totalRecords}';

DefaultConf.DialogCrup.TitleCreateHeader = 'Create';
DefaultConf.DialogCrup.TitleCloneHeader = 'Clone';
DefaultConf.DialogCrup.TitleUpdateHeader = 'Update';
DefaultConf.DialogCrup.LabelSave = 'Save';
DefaultConf.DialogCrup.LabelClose = 'Close';

DefaultConf.Form.ValidatorRequired = 'is required';
DefaultConf.Form.ValidatorPattern = 'pattern invalid';

document.documentElement.style.fontSize = '14px';

const root = createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <HashRouter>
    <ScrollToTop>
      <App/>
      <AppToast/>
      <AppConfirmDialog/>
    </ScrollToTop>
  </HashRouter>
);

