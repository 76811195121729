import {Link} from 'react-router-dom';

export const Header = () => {

  const someValidPath = () => {
    return undefined;
  }

  return (
    <header>
      <div className="header-area header-style-two">
        <div className="container">
          <div className="row">
            <div className="col-xl-2 align-items-center d-xl-flex d-lg-block">
              <div className="nav-logo d-flex justify-content-between align-items-center">
                <Link to="/">
                  <div style={{fontWeight: 400, fontFamily: "'Audiowide', cursive", fontSize: '3.5rem'}}>Whychain</div>
                </Link>
                <div className="d-flex align-items-center gap-4">
                  <ul className="nav-actions nav-inner-actions d-flex d-xl-none">

                    <li className="header-account me-0 position-relative">
                      <a href={someValidPath()} id="dropdownMenu3" data-bs-toggle="dropdown">
                        <i className="bi bi-person-fill"></i>
                      </a>
                      <ul className="dropdown-menu account-list-mobile" aria-labelledby="dropdownMenu3">
                        <li className="dropdown-item"><a href={someValidPath()}>My account</a></li>
                        <li className="dropdown-item"><a href={someValidPath()}>Settings</a></li>
                        <li className="dropdown-item"><a href={someValidPath()}>Login</a></li>
                        <li className="dropdown-item"><a href={someValidPath()}>Register</a></li>
                      </ul>
                    </li>
                  </ul>
                  <div className="mobile-menu d-flex ">
                    <a href={someValidPath()} className="hamburger d-block d-xl-none">
                      <span className="h-top"></span>
                      <span className="h-middle"></span>
                      <span className="h-bottom"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-7">
              <nav className="main-nav text-xl-center text-start">
                <div className="inner-logo d-xl-none mt-4 text-center">
                  <a href={someValidPath()}><img src={'/assets/iam/logos/IAM-Logo-200.png'} alt=""/></a>
                </div>
                <ul>
                  <li className="has-child-menu">
                    <a href={someValidPath()}>Assets</a>
                    <i className="fl flaticon-plus">+</i>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/accounts">
                          My Accounts (NFTs)
                        </Link>
                      </li>
                      <li>
                        <Link to="/holdings">
                          Holdings
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="has-child-menu">
                    <a href={someValidPath()}>News</a>
                    <i className="fl flaticon-plus">+</i>
                    <ul className="sub-menu">
                      <li><Link to="/pages/blog">Blog</Link></li>
                      <li><Link to="/pages/blog-standard">Blog Standard</Link></li>
                      <li><Link to="/pages/blog-details">Blog Details</Link></li>
                    </ul>
                  </li>
                  <li><Link to="/pages/about">about us</Link></li>
                  <li style={{paddingLeft: '12px'}}><Link to="/pages/contact">contact</Link></li>
                </ul>
                <ul className="inner-social-icons d-flex justify-content-center d-xl-none">
                  <li><a href="https://www.facebook.com"><i className="bx bxl-facebook"></i></a></li>
                  <li><a href="https://twitter.com"><i className="bx bxl-twitter"></i></a></li>
                  <li><a href="https://www.pinterest.com"><i className="bx bxl-pinterest"></i></a></li>
                  <li><a href="https://www.instagram.com"><i className="bx bxl-instagram"></i></a></li>
                </ul>
              </nav>
            </div>
            <div className="col-xl-3 d-none d-xl-flex align-items-center justify-content-end">
              <div className="nav-right d-flex align-items-center">
                <ul className="nav-actions">
                  <li className="search-btn"><i className="bi bi-search"></i></li>
                </ul>
                <div className="contact-no d-flex align-items-center">
                  <div className="contact-icon">
                    <img src={'assets/images/icon/phone.svg'} alt=""/>
                  </div>
                  <div className="contact-info">
                    <p>Hot Line Number</p>
                    <h6><a href="tel:+84 776322555">+84 776.322.555</a></h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}