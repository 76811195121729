import {Fragment} from 'react';
import {Link} from 'react-router-dom';

import {Breadcrumb} from '../components/layout/Breadcrumb';
import {Pagination} from '../components/Pagination';

export const BlogStandard = () => {

  return (
    <Fragment>
      <Breadcrumb title="Blog Standard"/>

      <div className="blog-standard-wrapper pt-110 pb-110">
        <div className="container">
          <div className="row gy-5">
            <div className="col-lg-8">
              <div className="blog-standard-alpha">
                <div className="thumb">
                  <img src="assets/images/blog/b-standard1.png" alt=""/>
                    <ul className="share-options">
                      <li><a href="https://www.facebook.com"><i className="bx bxl-facebook"></i></a></li>
                      <li><a href="https://twitter.com"><i className="bx bxl-twitter"></i></a></li>
                      <li><a href="https://www.pinterest.com"><i className="bx bxl-pinterest"></i></a></li>
                      <li><a href="https://www.instagram.com"><i className="bx bxl-instagram"></i></a></li>
                    </ul>
                </div>
                <div className="blog-body">
                  <div className="author-info">
                    <div className="author">
                      <div className="author-thumb"><img src="assets/images/blog/blog-author-x.png" alt=""/></div>
                      <div className="author-disc">
                        <h5>Jameron Williamson</h5>
                        <span>Car Auction</span>
                      </div>
                    </div>
                    <span className="date"><i className="bi bi-clock"></i> March 9, 2021</span>
                  </div>
                  <h3 className="blog-title"><Link to="/pages/blog-details">
                    How to Determine a Bidding Strategy for Different Types of Ads Different Good
                  </Link></h3>
                  <p>When I first learned how to ride a bike, I remember being scared. It was a daunting process because I'd never done it before and was terrified of falling. I actually remember feeling similarly when I first started getting into paid advertising campaigns at the marketing </p>
                  <a href="blog-details.html" className="readme-btn">Read More</a>
                </div>
              </div>
              <div className="blog-standard-alpha mt-50">
                <div className="thumb">
                  <img src="assets/images/blog/b-standard2.png" alt=""/>
                    <ul className="share-options">
                      <li><a href="https://www.facebook.com"><i className="bx bxl-facebook"></i></a></li>
                      <li><a href="https://twitter.com"><i className="bx bxl-twitter"></i></a></li>
                      <li><a href="https://www.pinterest.com"><i className="bx bxl-pinterest"></i></a></li>
                      <li><a href="https://www.instagram.com"><i className="bx bxl-instagram"></i></a></li>
                    </ul>
                </div>
                <div className="blog-body">
                  <div className="author-info">
                    <div className="author">
                      <div className="author-thumb"><img src="assets/images/blog/blog-author-x.png" alt=""/></div>
                      <div className="author-disc">
                        <h5>Jameron Williamson</h5>
                        <span>Car Auction</span>
                      </div>
                    </div>
                    <span className="date"><i className="bi bi-clock"></i> March 9, 2021</span>
                  </div>
                  <h3 className="blog-title"><Link to="/pages/blog-details">
                    Proofing your blog post is the last step in the setup of an SEO friendly auction.
                  </Link></h3>
                  <p>When I first learned how to ride a bike, I remember being scared. It was a daunting process because I'd never done it before and was terrified of falling. I actually remember feeling similarly when I first started getting into paid advertising campaigns at the marketing </p>
                  <a href="blog-details.html" className="readme-btn">Read More</a>
                </div>
              </div>
              <div className="blog-standard-alpha mt-50">
                <div className="thumb">
                  <img src="assets/images/blog/b-standard3.png" alt=""/>
                    <ul className="share-options">
                      <li><a href="https://www.facebook.com"><i className="bx bxl-facebook"></i></a></li>
                      <li><a href="https://twitter.com"><i className="bx bxl-twitter"></i></a></li>
                      <li><a href="https://www.pinterest.com"><i className="bx bxl-pinterest"></i></a></li>
                      <li><a href="https://www.instagram.com"><i className="bx bxl-instagram"></i></a></li>
                    </ul>
                </div>
                <div className="blog-body">
                  <div className="author-info">
                    <div className="author">
                      <div className="author-thumb"><img src="assets/images/blog/blog-author-x.png" alt=""/></div>
                      <div className="author-disc">
                        <h5>Jameron Williamson</h5>
                        <span>Car Auction</span>
                      </div>
                    </div>
                    <span className="date"><i className="bi bi-clock"></i> March 9, 2021</span>
                  </div>
                  <h3 className="blog-title"><Link to="/pages/blog-details">
                    Fifth consecutive year that Hyundai is recognized by Jameron Williamson
                  </Link></h3>
                  <p>When I first learned how to ride a bike, I remember being scared. It was a daunting process because I'd never done it before and was terrified of falling. I actually remember feeling similarly when I first started getting into paid advertising campaigns at the marketing </p>
                  <a href="blog-details.html" className="readme-btn">Read More</a>
                </div>
              </div>

              <Pagination/>
            </div>
            <div className="col-lg-4">
              <form className="sidebar-search">
                <div className="form-inner d-flex justify-content-center ">
                  <input type="text" placeholder="Enter Keward"/>
                    <button className="search-btn">SEARCH</button>
                </div>
              </form>
              <div className="blog-sidebar">
                <div className="blog-widget widget-categories">
                  <h5 className="wdget-title">News Category</h5>
                  <ul className="widget-body">
                    <li><a href="/#"><span>BMW Car Bidding</span> <img src="assets/images/icon/arrow-right-fancy.svg" alt=""/></a></li>
                    <li><a href="/#"><span>Business</span> <img src="assets/images/icon/arrow-right-fancy.svg" alt=""/></a></li>
                    <li><a href="/#"><span>New Tecnologies</span> <img src="assets/images/icon/arrow-right-fancy.svg" alt=""/></a></li>
                    <li><a href="/#"><span>Data Center</span> <img src="assets/images/icon/arrow-right-fancy.svg" alt=""/></a></li>
                    <li><a href="/#"><span>Network Setup</span> <img src="assets/images/icon/arrow-right-fancy.svg" alt=""/></a></li>
                    <li><a href="/#"><span>Audi Car Bidding</span> <img src="assets/images/icon/arrow-right-fancy.svg" alt=""/></a></li>
                  </ul>
                </div>
                <div className="blog-widget widget-categories">
                  <h5 className="wdget-title">Latest Posts</h5>
                  <div className="latest-post-single">
                    <div className="post-img">
                      <img src="assets/images/blog/lp1.png" alt=""/>
                    </div>
                    <div className="post-content">
                      <h6><Link to="/pages/blog-details">Security Council Diplomats to Have Lunch.</Link></h6>
                      <p>5 February, 2021</p>
                    </div>
                  </div>
                  <div className="latest-post-single">
                    <div className="post-img">
                      <img src="assets/images/blog/lp2.png" alt=""/>
                    </div>
                    <div className="post-content">
                      <h6><Link to="/pages/blog-details">How Can My Business Win?</Link></h6>
                      <p>5 February, 2021</p>
                    </div>
                  </div>
                  <div className="latest-post-single">
                    <div className="post-img">
                      <img src="assets/images/blog/lp3.png" alt=""/>
                    </div>
                    <div className="post-content">
                      <h6><Link to="/pages/blog-details">Genocide’s Legacy: Ation Villa ge in Rwanda</Link></h6>
                      <p>5 February, 2021</p>
                    </div>
                  </div>
                </div>
                <div className="blog-widget widget-categories">
                  <h5 className="wdget-title">News Tag</h5>
                  <ul className="sidebar-tag-list">
                    <li><a href="/#">Survey</a></li>
                    <li><a href="/#">Data</a></li>
                    <li><a href="/#">Research</a></li>
                    <li><a href="/#">Data</a></li>
                    <li><a href="/#">Khusi</a></li>
                    <li><a href="/#">Customer</a></li>
                    <li><a href="/#">Setup</a></li>
                    <li><a href="/#">VAS</a></li>
                  </ul>
                </div>
                <div className="blog-widget widget-categories">
                  <h5 className="wdget-title">Our Follower</h5>
                  <ul className="followers-list">
                    <li><a href="https://www.facebook.com"><span><i className="bx bxl-facebook"></i>Facebook</span><span>50,520</span></a></li>
                    <li><a href="https://twitter.com"><span><i className="bx bxl-twitter"></i>Twitter</span><span>42,035</span></a></li>
                    <li><a href="https://www.instagram.com"><span><i className="bx bxl-instagram"></i>Instagram</span><span>3,850</span></a></li>
                    <li><a href="https://www.pinterest.com"><span><i className="bx bxl-pinterest-alt"></i>Pinterest</span><span>10,590</span></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}