import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {ToastService, useDataTableBasic} from '@iamsoftware/react-hooks';

import {NftAssetsService} from '../../../service/common/NftAssetsService';
import {NftOfferService} from '../../../service/common/NftOfferService';
import {NftAskService} from '../../../service/common/NftAskService';
import {TransactionService} from '../../../service/common/TransactionService';

import {usePolkadot} from '../../../shared/substrate-lib/Polkadot';

export const BiddingHistory = ({iamUser, asset, offer, dataState, setDataState, setProductDataState}) => {

  const [items, setItems] = useState([]);

  const [submitting, setSubmitting] = useState(false);

  const {signedTx} = usePolkadot();
  const [txStatus, setTxStatus] = useState(null);

  useEffect(() => {
    if (offer?._id) {
      const event = {
        filters: {
          nftOffer: {value: offer._id, matchMode: 'ObjectId'}
        }
      };
      NftAskService.getList(JSON.stringify(event)).then(data => {
        setItems(data.items);
      });
    } else {
      setItems([]);
    }
  }, [offer?._id, dataState]);

  const {render: renderDataTable, selectedItem} = useDataTableBasic({
    dataKey: '_id',
    columns: [
      {field: 'created_date', header: 'Date', width: 160, dataType: 'date-time'},
      {
        field: 'user', header: 'From User', minWidth: 150, dataType: 'custom', customCell(rowData: any): any {
          return rowData?.user && rowData.user.name;
        }
      },
      {field: 'amount', header: 'Amount', width: 120, dataType: 'number'},
      {field: 'status', header: 'Status', width: 120}
    ],
    items
  });

  const accept = () => {
    if (!submitting) {
      if (selectedItem?._id) {

        setSubmitting(true);
        if (offer?.type === 'Auction') {
          Promise.all([
            NftOfferService.update(offer._id, {
              status: 'Accepted',
              auction: {
                accept: selectedItem
              }
            }),
            NftAskService.update(selectedItem._id, {status: 'Accepted'}),
          ]).then(() => {
            ToastService.success();
            setDataState(Date.now());
            setProductDataState(Date.now());
          });
        } else {
          setTxStatus(null);

          const setResult = result => {
            if (result) {
              if (result.status) {
                setTxStatus(result.status);
              }
              if (result.isFinalized) {
                setSubmitting(false);
              }
              if (result.blockHash) {
                Promise.all([
                  TransactionService.create({
                    user: asset._id,
                    type: 'NftActivity',
                    description: `Transfer NFT from ${asset.user.name} to ${selectedItem.user.name}`,
                    symbol: offer.priceOrg,
                    quantity: selectedItem.amount,
                    block_hash: result.blockHash
                  }),
                  NftOfferService.update(offer._id, {status: 'Completed'}),
                  NftAskService.update(selectedItem._id, {status: 'Accepted'}),
                  NftAssetsService.update(asset._id, {user: selectedItem.user._id, status: 'Open'})
                ]).then(() => {
                  ToastService.success();
                  setDataState(Date.now());
                  setProductDataState(Date.now());
                });
              }
            }
          }

          signedTx('nftwcnModule', 'transferNft', [selectedItem.user.address, asset.key], setResult);
        }
      } else {
        ToastService.error('No ask selected');
      }
    }
  }

  const acceptArea = () => {
    const area = (
      <Fragment>
        <div className="col-fixed" style={{width: '110px'}}>
          <Button label="Accept" icon="pi pi-bolt" onClick={accept} loading={submitting} className="p-button-info p-button-sm"/>
        </div>
        <div className="col">
          <b>{txStatus}</b>
        </div>
      </Fragment>
    );
    switch (offer?.status) {
      case 'Open':
        if (offer.user._id === iamUser._id) {
          if (offer?.type === 'Auction') {
            if (offer.thruDate && (new Date(offer.thruDate).getTime() - Date.now()) < 1000 * 60 * 5) {
              return area;
            } else {
              return <div className="col"><b>Waiting for bid</b></div>
            }
          } else {
            return area;
          }
        }
        break;
      case 'Accepted':
        if (offer.user._id === iamUser._id) {
          return <div className="col"><b>Waiting for user confirmation.</b></div>
        }
        break;
    }
  }

  return (
    <div className="single-widget">
      <h5 className="wdget-title">Bidding History</h5>

      {acceptArea()}

      {renderDataTable()}

    </div>
  );
}