import {Fragment, useState} from 'react';

import {Button} from 'primereact/button';
import {useDataTableBasic, useDialogCrup} from '@iamsoftware/react-hooks';

import {TransactionService} from '../../service/common/TransactionService';

import {usePolkadot} from '../../shared/substrate-lib/Polkadot';

export const Balances = ({iamUser, balances}) => {

  const {signedTx} = usePolkadot();

  const [txStatus, setTxStatus] = useState(null);

  const {render: renderDialogDeposit, create} = useDialogCrup({
    header: '!Deposit',
    width: '40rem',
    fields: [
      {field: 'code', type: 'hidden'},
      {field: 'amount', header: 'Amount', required: true, className: 'md:col-12'},
      {type: 'custom', className: 'md:col-12', body: <b>{txStatus}</b>}
    ],
    createItem(item: any): Promise<any> {
      return new Promise<void>(resolve => {

        const setResult = result => {
          if (result) {
            if (result.status) {
              setTxStatus(result.status);
            }
            if (result.isFinalized) {
              resolve();
            }
            if (result.blockHash) {
              TransactionService.create({
                user: iamUser._id,
                type: 'Deposit',
                description: 'Deposit Loyalty',
                symbol: item.code,
                quantity: item.amount,
                block_hash: result.blockHash
              }).then();
            }
          }
        }

        signedTx('nftwcnModule', 'createCurrency', [item.amount, item.code], setResult);
      });
    }
  });

  const deposit = currency => {
    setTxStatus(null);
    create({code: currency.value});
  }

  const {render: renderDataTable} = useDataTableBasic({
    indexColumnWidth: 80,
    columns: [
      {field: 'value', header: 'Symbol', width: 200, style: {justifyContent: 'center'}},
      {field: 'label', header: 'Name', minWidth: 150, style: {justifyContent: 'center'}},
      {field: 'availableBalance', header: 'Available Balance', width: 170, dataType: 'number'},
      {
        field: 'deposit', header: 'Deposit', width: 150, sortable: false, dataType: 'custom', customCell(rowData: any): any {
          return <Button icon="pi pi-plus" className="p-button-rounded p-button-sm" onClick={() => deposit(rowData)}/>
        }, style: {justifyContent: 'center'}
      }
    ],
    items: balances
  });

  return (
    <Fragment>
      <div className="mt-4">
        {renderDataTable()}
      </div>

      {renderDialogDeposit()}
    </Fragment>
  );
}