import {Fragment} from 'react';
import {Link} from 'react-router-dom';

import {Breadcrumb} from '../components/layout/Breadcrumb';

export const Login = () => {

  return (
    <Fragment>
      <Breadcrumb title="Login"/>

      <section className="register-section pt-110 pb-110">
        <div className="container">
          <div className="row d-flex justify-content-center text-center">
            <div className="col-12">
              <div className="register-btn-group">
                <Link to="/pages/login" className="register-btn style2">Register</Link>
                <Link to="/pages/login" className="login-btn style2">Login</Link>
              </div>
            </div>
          </div>
          <div className="row gy-4">
            <div className="col-lg-6">
              <div className="register-left-area style2">
                <h3>Hello Again</h3>
                <p>Wellcome Back You’ve Been Missed</p>
                <a href="/#" className="transparent-btn">Back Home</a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="right-form-area">
                <h3>Login Your Account</h3>
                <form className="w-100">
                  <div className="form-inner">
                    <label>User Name*</label>
                    <input type="text" placeholder="User Name"/>
                  </div>
                  <div className="form-inner">
                    <label>Password *</label>
                    <input type="password" name="password" id="password" placeholder="Password"/>
                    <i className="bi bi-eye-slash" id="togglePassword"></i>
                  </div>
                  <div className="form-inner d-flex justify-content-between flex-wrap">
                    <div className="form-group">
                      <input type="checkbox" id="html"/>
                      <label htmlFor="html">I agree to the Terms &amp; Policy</label>
                    </div>

                    <a href="/#" className="forgot-pass">Forgotten Password</a>
                  </div>
                  <button className="account-btn">LOGIN ACCOUNT</button>
                </form>
                <div className="alternate-signup-box">
                  <h6>or Sign up WITH</h6>
                  <div className="btn-group gap-4">
                    <a href="/#" className="google-btn d-flex align-items-center"><i className="bx bxl-google"></i><span>Signup whit google</span></a>
                    <a href="/#" className="facebook-btn d-flex align-items-center"><i className="bx bxl-facebook"></i>Sign up whit facebook</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}