import {Link} from 'react-router-dom';

export const Breadcrumb = ({title}) => {

  return (
    <div className="breadcrumb breadcrumb-style-one mb-0">
      <div className="container">
        <div className="col-lg-12 text-center">
          <h1 className="breadcrumb-title">{title}</h1>
          <ul className="d-flex justify-content-center breadcrumb-items">
            <li className="breadcrumb-item"><i className="bi bi-house-door"></i>
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">{title}</li>
          </ul>
        </div>
      </div>
    </div>
  );
}