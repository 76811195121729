import {Subject} from 'rxjs';
import {io} from 'socket.io-client';

const socketMessage = new Subject();

const socket = io({
  path: '/erp/socket.io'
});

socket.on('refresh', data => {
  socketMessage.next(data);
});

export {socketMessage};