export const Topbar = ({iamUsers, iamUser, setIamUser}) => {

  const someValidPath = () => {
    return undefined;
  }
  
  return (
    <div className="topbar-area topbar-style-one py-2 py-md-0">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-5 col-md-4 col-sm-6 col">
            <div className="topbar-mail">
              <i className="bi bi-envelope"></i> <a href="mailto:support@whychain.net">support@whychain.net</a>
            </div>
          </div>
          <div className="col-xl-2 col-md-4 d-none d-md-block">
            <ul className="topbar-social-icons d-flex justify-content-center">
              <li><a href="https://www.facebook.com"><i className="bx bxl-facebook"></i></a></li>
              <li><a href="https://twitter.com"><i className="bx bxl-twitter"></i></a></li>
              <li><a href="https://www.pinterest.com"><i className="bx bxl-pinterest"></i></a></li>
              <li><a href="https://www.instagram.com"><i className="bx bxl-instagram"></i></a></li>
            </ul>
          </div>
          <div className="col-xl-5 col-md-4 col-sm-6 col">
            <ul className="topbar-actions d-flex justify-content-end ms-3">
              <li className="header-account me-0 position-relative">
                <a href={someValidPath()} id="dropdownMenu5" data-bs-toggle="dropdown">
                  <i className="bi bi-translate"></i>
                </a>
                <ul className="dropdown-menu account-list" aria-labelledby="dropdownMenu5">
                  <li className="dropdown-item"><a href={someValidPath()}>Eng</a></li>
                  <li className="dropdown-item"><a href={someValidPath()}>বাংলা</a></li>
                  <li className="dropdown-item"><a href={someValidPath()}>中国</a></li>
                  <li className="dropdown-item"><a href={someValidPath()}>española</a></li>
                </ul>
              </li>

              <li className="header-account me-0 position-relative ms-3">
                <a href={someValidPath()} id="dropdownMenu4" data-bs-toggle="dropdown">
                  <i className="bi bi-person-vcard"></i>
                  <span style={{fontSize: '13px', color: 'white', marginLeft: '5px'}}>{iamUser?.name}</span>
                </a>
                <ul className="dropdown-menu account-list" aria-labelledby="dropdownMenu4">
                  {iamUsers.map((item, index) => <li key={index} className="dropdown-item">
                    <a href={someValidPath()} onClick={() => setIamUser(item)}>{item.name}</a>
                  </li>)}
                </ul>
              </li>

            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}