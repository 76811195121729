import {useEffect, useState} from 'react';

import {NftOfferService} from '../../../service/common/NftOfferService';
import {NftAssetsService} from '../../../service/common/NftAssetsService';

import {ProductBetaMd} from '../../components/Product';

export const LiveAuction = ({iamUser, balances}) => {

  const [assets, setAssets] = useState([]);

  useEffect(() => {
    if (balances?.length) {
      const event = {
        filters: {
          priceOrg: {value: balances.map(item => item.value), matchMode: 'in'},
          fromDate: {value: new Date(), matchMode: 'lte'},
          thruDate: {value: new Date(), matchMode: 'gte'},
          status: {value: 'Open', matchMode: 'equals'}
        }
      };
      NftOfferService.getList(JSON.stringify(event)).then(({items}) => {
        if (items.length) {
          const event = {
            filters: {
              _id: {value: items.map(item => item.nftAsset._id), matchMode: 'in'}
            }
          };
          NftAssetsService.getList(JSON.stringify(event)).then(data => {
            setAssets(data?.items);
          });
        }
      });
    }
  }, [balances]);

  return (
    <div className="live-auction-area mt-110">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-header-two">
              <h3>Live Auction</h3>
            </div>
          </div>
        </div>
        <div className="row g-4">
          {assets.map((item, index) => <div key={index} className="col-lg-4 col-md-6">
            <ProductBetaMd iamUser={iamUser} asset={item} fontSize="3rem"/>
          </div>)}
          <div className="view-btn mt-50 text-center">
            <a href={someValidPath()} className="alpha-btn-md-dark">View All</a>
          </div>
        </div>
      </div>
    </div>
  );
}

const someValidPath = () => {
  return undefined;
}