import {Fragment, useEffect, useState} from 'react';

import {useDataTable} from '@iamsoftware/react-hooks';

import {TransactionService as Service} from '../../service/common/TransactionService';

export const Transactions = ({iamUser, type}) => {

  const dataKey = '_id';

  const [requiredParams, setRequiredParams] = useState(null);

  useEffect(() => {
    if (iamUser) {
      setRequiredParams({
        user: {value: iamUser._id, matchMode: 'ObjectId'},
        type: {value: type, matchMode: 'equals'}
      });
    }
  }, [iamUser, type]);

  const {render: renderDataTable} = useDataTable({
    dataKey,
    columns: [
      {field: 'created_date', header: 'Date', width: 170, dataType: 'date-time'},
      {field: 'symbol', header: 'Symbol', width: 100, style: {justifyContent: 'center'}},
      {field: 'quantity', header: 'Quantity', width: 130, dataType: 'number'},
      {field: 'description', header: 'Description', minWidth: 200},
      {field: 'block_hash', header: 'Block Hash', minWidth: 200}
    ],
    indexColumnWidth: 40,
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },
    requiredParams
  });

  return (
    <div className="mt-4">
      {renderDataTable()}
    </div>
  );
}