import {Buffer} from 'buffer';

import {web3FromSource} from '@polkadot/extension-dapp';

import {useSubstrate} from './SubstrateContext';

export const usePolkadot = () => {

  const {
    setCurrentAccount,
    state: {api, keyring, currentAccount}
  } = useSubstrate();

  const toString = (input) => {
    return Buffer.from(input.replace(/0x/g, ''), 'hex').toString('utf8');
  }

  const setAccount = (address) => {
    setCurrentAccount(keyring.getPair(address));
  }

  const getFromAcct = () => {
    return new Promise<Array<any>>(resolve => {
      const {
        address,
        meta: {source, isInjected},
      } = currentAccount

      if (!isInjected) {
        resolve([currentAccount]);
      } else {
        web3FromSource(source).then(injector => {
          resolve([address, {signer: injector.signer}]);
        });
      }
    });
  }

  const signedTx = (palletRpc: string, callable: string, inputParams: Array<any>, resultHandler) => {
    if (api.tx[palletRpc] && api.tx[palletRpc][callable]) {

      resultHandler({status: 'Sending...'});

      const txExecute = api.tx[palletRpc][callable](...inputParams);

      getFromAcct().then(fromAcct => {
        txExecute.signAndSend(...fromAcct, (result) => {
          const status = result.status.isFinalized
            ? `😉 Finalized. Block hash: ${result.status.asFinalized.toString()}`
            : `Current transaction status: ${result.status.type}`;

          const blockHash = result.status.isFinalized ? result.status.asFinalized.toString() : null;

          resultHandler({status, isFinalized: result.status.isFinalized, blockHash});
        }).catch(err => {
          resultHandler({status: `😞 Transaction Failed: ${err.toString()}`, isFinalized: true});
        });
      });
    } else {
      resultHandler({status: 'Connection failed.'});
    }
  }

  const query = (palletRpc: string, callable: string, inputParams: Array<any>, resultHandler) => {
    if (api.query[palletRpc] && api.query[palletRpc][callable]) {
      api.query[palletRpc][callable](...inputParams, result => {
        result.isNone ? resultHandler('None') : resultHandler(result.toString())
      });
    } else {
      resultHandler('Connection failed.');
    }
  }

  return {toString, currentAccount, setAccount, signedTx, query};

}