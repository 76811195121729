import {Fragment, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import {ConfirmDialogService, FormatDisplay, ToastService, useDialogCrup} from '@iamsoftware/react-hooks';

import {NftAssetsService} from '../../service/common/NftAssetsService';
import {NftOfferService} from '../../service/common/NftOfferService';
import {NftAskService} from '../../service/common/NftAskService';
import {TransactionService} from '../../service/common/TransactionService';

import {socketMessage} from '../../service/socket';

import {usePolkadot} from '../../shared/substrate-lib/Polkadot';

import {useProductData} from '../components/Product';

import {Breadcrumb} from '../components/layout/Breadcrumb';

import {BiddingHistory} from './components/BiddingHistory';
import {Describe} from './components/Describe';

export const AccountDetail = ({iamUser, balances}) => {

  const {id} = useParams();

  const [asset, setAsset] = useState(null);
  const [availableBalance, setAvailableBalance] = useState(0);

  const [submitting, setSubmitting] = useState(false);
  const [amount, setAmount] = useState('' as any);

  const {signedTx} = usePolkadot();
  const [txStatus, setTxStatus] = useState(null);

  const [dataState, setDataState] = useState(0);

  useEffect(() => {
    const subscriber = socketMessage.subscribe(data => {
      if (data === id) {
        setDataState(Date.now());
        setProductDataState(Date.now());
      }
    });
    return () => {
      subscriber.unsubscribe();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (id) {
      let event: any = {
        filters: {
          _id: {value: id, matchMode: 'ObjectId'}
        }
      };
      NftAssetsService.getList(JSON.stringify(event)).then(data => {
        if (data?.items && data.items?.length) {
          setAsset(data.items[0]);
        } else {
          setAsset(null);
        }
      });
    }
  }, [id, dataState]);

  const {offer, currentBid, setProductDataState} = useProductData({asset_id: id});

  useEffect(() => {
    let _availableBalance = 0;
    if (offer?.priceOrg && balances) {
      balances.forEach(balance => {
        if (balance.value === offer?.priceOrg) {
          _availableBalance = balance.availableBalance;
        }
      });
    }
    setAvailableBalance(_availableBalance);
  }, [offer?.priceOrg, balances]);

  useEffect(() => {
    if (currentBid) {
      setAmount(currentBid + 1);
    }
  }, [currentBid]);

  const onPlace = e => {
    if (amount > currentBid && amount <= availableBalance) {
      if (!submitting) {

        setSubmitting(true);

        if (offer?.type === 'Auction') {
          NftAskService.create({
            user: iamUser._id,
            nftOffer: offer._id,
            amount,
            status: 'Open'
          }).finally(() => {
            setSubmitting(false);
          });
        } else {
          setTxStatus(null);

          const setResult = result => {
            if (result) {
              if (result.status) {
                setTxStatus(result.status);
              }
              if (result.isFinalized) {
                setSubmitting(false);
              }
              if (result.blockHash) {
                TransactionService.create({
                  user: offer.nftAsset,
                  type: 'NftActivity',
                  description: `Ask by ${iamUser.name}`,
                  symbol: offer.priceOrg,
                  quantity: amount,
                  block_hash: result.blockHash
                }).then();

                TransactionService.create({
                  user: offer.user._id,
                  type: 'Deposit',
                  description: 'Deposit Point Ask NFT',
                  symbol: offer.priceOrg,
                  quantity: amount,
                  block_hash: result.blockHash
                }).then();
                TransactionService.create({
                  user: iamUser._id,
                  type: 'Withdrawal',
                  description: 'Withdrawal Point Ask NFT',
                  symbol: offer.priceOrg,
                  quantity: amount,
                  block_hash: result.blockHash
                }).then();
                NftAskService.create({
                  user: iamUser._id,
                  nftOffer: offer._id,
                  amount,
                  status: 'Open'
                }).finally(() => {
                  setSubmitting(false);
                });
              }
            }
          }

          signedTx('nftwcnModule', 'transferCurrency', [offer.user.address, offer.priceOrg, amount], setResult);
        }
      }
    } else {
      ToastService.error('Amount invalid!');
    }

    e.preventDefault();
  }

  const doReject = () => {
    if (!submitting) {
      setSubmitting(true);

      Promise.all([
        NftOfferService.update(offer._id, {status: 'Completed'}),
        NftAssetsService.update(asset._id, {status: 'Open'})
      ]).finally(() => {
        setSubmitting(false);
      });
    }
  }
  const doConfirm = () => {
    const accept_amount = offer.auction.accept.amount;
    if (!submitting && accept_amount > 0) {
      setSubmitting(true);

      setTxStatus(null);

      const setResult = result => {
        if (result) {
          if (result.status) {
            setTxStatus(result.status);
          }
          if (result.isFinalized) {
            setSubmitting(false);
          }
          if (result.blockHash) {
            TransactionService.create({
              user: offer.nftAsset,
              type: 'NftActivity',
              description: `Ask by ${iamUser.name}`,
              symbol: offer.priceOrg,
              quantity: amount,
              block_hash: result.blockHash
            }).then();

            TransactionService.create({
              user: offer.user._id,
              type: 'Deposit',
              description: 'Deposit Point Ask NFT',
              symbol: offer.priceOrg,
              quantity: amount,
              block_hash: result.blockHash
            }).then();
            TransactionService.create({
              user: iamUser._id,
              type: 'Withdrawal',
              description: 'Withdrawal Point Ask NFT',
              symbol: offer.priceOrg,
              quantity: amount,
              block_hash: result.blockHash
            }).then();
            NftOfferService.update(offer._id, {
              status: 'Confirmed'
            }).finally(() => {
              setSubmitting(false);
            });
          }
        }
      }

      signedTx('nftwcnModule', 'transferCurrency', [offer.user.address, offer.priceOrg, accept_amount], setResult);
    }
  }

  const placeArea = () => {
    const area = (
      <div className="single-widget">
        <h5 className="wdget-title wdget-title2">Bid Now</h5>
        <p className="widget-subtitle">Bid Amount : Minimum Bid <span style={{fontWeight: 'normal'}}>{offer?.priceOrg}</span> {FormatDisplay.number((currentBid + 1))}</p>
        <form className="widget-form" onSubmit={onPlace}>
          <div className="form-group d-flex justify-content-center align-items-center">
            <input type="number" value={amount} onChange={e => setAmount(e.target.value as any)} required/>
            <button className="widget-btn">Place Bid</button>
          </div>
        </form>
        <i>Available: {FormatDisplay.number(availableBalance)}</i>
        <p><b>{txStatus}</b></p>
      </div>
    );
    switch (offer?.status) {
      case 'Open':
        if (iamUser._id !== asset?.user?._id) {
          if (offer?.type === 'Auction') {
            if (offer.thruDate && (new Date(offer.thruDate).getTime() - Date.now()) > 1000 * 60 * 5) {
              return area;
            } else {
              return (<div className="single-widget">
                <h5 className="wdget-title wdget-title2">Bid End. Waiting result..</h5>
              </div>);
            }
          } else {
            return area;
          }
        }
        break;
      case 'Accepted':
        if (iamUser._id !== asset?.user?._id) {
          if (offer?.auction && offer.auction.accept?.user && offer.auction.accept.user._id === iamUser._id) {
            return (<div className="single-widget">
              <h5 className="wdget-title wdget-title2">Your bid has been Accepted by Seller.</h5>
              <form className="widget-form text-center" onSubmit={e => e.preventDefault()}>
                <button className="widget-btn mr-2" onClick={doConfirm}>Confirm</button>
                <button className="widget-btn" onClick={doReject}>Reject</button>
              </form>
              {txStatus}
            </div>);
          }
        }
        break;
      case 'Confirmed':
        if (iamUser._id !== asset?.user?._id) {
          if (offer?.auction && offer.auction.accept?.user && offer.auction.accept.user._id === iamUser._id) {
            return (<div className="single-widget">
              <h5 className="wdget-title wdget-title2">Your bid has been Confirmed by Seller.</h5>
            </div>);
          }
        }
        break;
    }
  }

  return (
    <Fragment>
      <Breadcrumb title="Account Detail"/>

      <div className="auction-details-section pt-110 pb-110">
        <div className="container">
          <div className="row gy-5">
            <div className="col-lg-6">
              {offer?._id && <div className="item-bid-timer">
								<div className="auction-bid">
                  {new Date(offer.fromDate) < new Date() && <p>Current Bid</p>}
                  {new Date(offer.fromDate) > new Date() && <p>Coming Soon</p>}

									<div>{offer?.priceOrg} <h5 style={{display: 'inline'}}>{FormatDisplay.number(currentBid)}</h5></div>
								</div>
								<div className="auction-timer">
									<div className="iam-countdown">
										<span style={{display: 'none'}}>{offer.thruDate}</span>
										<h6>0</h6>
									</div>
								</div>
							</div>}

              <div className="tab-content">
                <div className="tab-pane big-image fade show active" id="gallery-img1">
                  <span className="eg-badge">ID {asset?.key?.substring(8)}</span>
                  {asset?.image && <img src={asset?.image} alt=""/>}
                  {!asset?.image && <img src={'/assets/images/blank-bg.jpg'} alt=""/>}
                  <div className="name" style={{fontSize: '3.2rem'}}><span>{asset?.name}</span></div>
                </div>
              </div>

              <Describe asset={asset}/>
            </div>
            <div className="col-lg-6">
              <div className="widget-right-area">
                <h4>{asset?.description}</h4>
                <p className="paragraph">{asset?.short_description}</p>
                <div className="single-widget">
                  <h5 className="wdget-title">Product Overview</h5>
                  <ul className="widget-list">
                    <li><span>Name :</span><span>{asset?.description}</span></li>
                    <li><span>No :</span><span>{asset?.name}</span></li>
                    <li><span>Owned by :</span><span>{iamUser._id === asset?.user?._id ? 'you' : asset?.user?.name}</span></li>
                    {offer?._id && <Fragment>
                      {offer?.type && <li><span>Type :</span><span>{offer?.type}</span></li>}
											<li><span>Listing Price :</span><b><span style={{fontWeight: 'normal'}}>{offer?.priceOrg}</span> {FormatDisplay.number((offer?.price))}</b></li>
                      {!!currentBid && <li><span>Current Bid :</span><b><span style={{fontWeight: 'normal'}}>{offer?.priceOrg}</span> {FormatDisplay.number((currentBid))}</b></li>}
                    </Fragment>}
                  </ul>
                </div>
                {placeArea()}

                {iamUser._id === asset?.user?._id && <Ours iamUser={iamUser} balances={balances} asset={asset} offer={offer} setDataState={setDataState} setProductDataState={setProductDataState}/>}

                <BiddingHistory iamUser={iamUser} asset={asset} offer={offer} dataState={dataState} setDataState={setDataState} setProductDataState={setProductDataState}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const Ours = ({iamUser, balances, asset, offer, setDataState, setProductDataState}) => {

  const [type, setType] = useState(null);

  const [submitting, setSubmitting] = useState(false);

  const {signedTx} = usePolkadot();
  const [txStatus, setTxStatus] = useState(null);

  const doCreateItem = item => {

    TransactionService.create({
      user: asset._id,
      type: 'NftActivity',
      description: `List by ${iamUser.name}`,
      symbol: item.priceOrg,
      quantity: item.price,
      block_hash: ''
    }).then();

    item.user = iamUser._id;
    item.nftAsset = asset._id;
    item.status = 'Open';
    return NftOfferService.create(item);
  }

  const {render: renderDialogCrup, create, update, getValue} = useDialogCrup({
    header: `!List for ${type}`,
    dataKey: '_id',
    width: '40rem',
    fields: [
      {field: 'type', type: 'hidden'},
      {field: 'price', header: 'Price', required: true, type: 'InputNumber', className: 'md:col-6'},
      {field: 'priceOrg', header: 'Currency', required: true, type: 'Dropdown', DropdownProps: {options: balances}, className: 'md:col-6'},
      {field: 'fromDate', header: 'Starting', required: true, type: 'Calendar', CalendarProps: {showTime: true}, className: 'md:col-6'},
      {field: 'thruDate', header: 'Ending', required: true, type: 'Calendar', CalendarProps: {showTime: true}, className: 'md:col-6'}
    ],
    createItem(item: any): Promise<any> {
      return doCreateItem(item).then(() => {
        return NftAssetsService.update(asset._id, {status: 'Listing'});
      });
    },
    updateItem(id: string, item: any): Promise<any> {
      return NftOfferService.update(id, {status: 'Cancelled'}).then(() => {
        return doCreateItem(item);
      });
    },
    reloadLazyData: () => {
      setProductDataState(Date.now());
    }
  });

  useEffect(() => {
    setType(getValue().type);
  }, [getValue().type]);

  const doUpdateListing = () => {
    const _offer = Object.assign({}, offer);
    if (_offer.fromDate) {
      _offer.fromDate = new Date(_offer.fromDate)
    }
    if (_offer.thruDate) {
      _offer.thruDate = new Date(_offer.thruDate)
    }
    update(_offer);
  }

  const doCancelListing = () => {
    ConfirmDialogService.confirm('Cancel listing', 'Cancel this listing?', () => {
      NftOfferService.update(offer._id, {status: 'Cancelled'}).then(() => {
        setProductDataState(Date.now());
      });
    });
  }

  const doReject = () => {
    if (!submitting) {
      setSubmitting(true);

      Promise.all([
        NftOfferService.update(offer._id, {status: 'Completed'}),
        NftAssetsService.update(asset._id, {status: 'Open'})
      ]).finally(() => {
        setSubmitting(false);
      });
    }
  }

  const doConfirm = () => {
    if (!submitting) {
      setSubmitting(true);
      setTxStatus(null);

      const accept = offer.auction.accept;

      const setResult = result => {
        if (result) {
          if (result.status) {
            setTxStatus(result.status);
          }
          if (result.isFinalized) {
            setSubmitting(false);
          }
          if (result.blockHash) {
            Promise.all([
              TransactionService.create({
                user: asset._id,
                type: 'NftActivity',
                description: `Transfer NFT from ${asset.user.name} to ${accept.user.name}`,
                symbol: offer.priceOrg,
                quantity: accept.amount,
                block_hash: result.blockHash
              }),
              NftOfferService.update(offer._id, {status: 'Completed'}),
              NftAssetsService.update(asset._id, {user: accept.user._id, status: 'Open'})
            ]).then(() => {
              ToastService.success();
              setDataState(Date.now());
              setProductDataState(Date.now());
            });
          }
        }
      }

      signedTx('nftwcnModule', 'transferNft', [accept.user.address, asset.key], setResult);
    }
  }

  const actionArea = () => {
    if (!offer?._id) {
      return <Fragment>
        <button className="widget-btn mr-2" onClick={() => create({type: 'Sell', fromDate: new Date()})}>Sell</button>
        <button className="widget-btn" onClick={() => create({type: 'Auction', fromDate: new Date()})}>Auction</button>
      </Fragment>;
    } else {
      switch (offer?.status) {
        case 'Open':
          if (offer.user._id === iamUser._id) {
            return <Fragment>
              <button className="widget-btn mr-2" onClick={doUpdateListing}>Edit listing</button>
              <button className="widget-btn" onClick={doCancelListing}>Cancel listing</button>
            </Fragment>;
          }
          break;
        case 'Confirmed':
          if (offer.user._id === iamUser._id) {
            return (<div className="single-widget">
              <h5 className="wdget-title wdget-title2">Your offer has been Confirmed by Buyer.</h5>
              <div className="widget-form text-center">
                <button className="widget-btn mr-2" onClick={doConfirm}>Confirm</button>
                <button className="widget-btn" onClick={doReject}>Reject</button>
              </div>
              {txStatus}
            </div>);
          }
          break;
      }
    }
  }

  return (
    <form className="widget-form text-center" onSubmit={e => e.preventDefault()}>
      {actionArea()}

      {renderDialogCrup()}
    </form>
  );
}