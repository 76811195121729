
export const StepsToWin = () => {

  const someValidPath = () => {
    return undefined;
  }
  
  return (
    <div className="win-process-area mt-110">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-header-two text-center">
              <h3>Easy 03 Steps To win</h3>
            </div>
          </div>
        </div>
        <div className="row g-4 d-flex justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-process">
              <span className="corner-1"></span>
              <span className="corner-2"></span>
              <div className="icon mx-auto">
                <img src="assets/images/icon/process1.svg" alt=""/>
              </div>
              <div className="process-disc">
                <h4><a href={someValidPath()}>Log in</a></h4>
                <p>No Credit Card Required</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-process">
              <span className="corner-1"></span>
              <span className="corner-2"></span>
              <div className="icon mx-auto">
                <img src="assets/images/icon/process2.svg" alt=""/>
              </div>
              <div className="process-disc">
                <h4><a href={someValidPath()}>Bid Now</a></h4>
                <p>Bidding is Free Only If You Win</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-process">
              <span className="corner-1"></span>
              <span className="corner-2"></span>
              <div className="icon mx-auto">
                <img src="assets/images/icon/process3.svg" alt=""/>
              </div>
              <div className="process-disc">
                <h4><a href={someValidPath()}>Win</a></h4>
                <p>Fun - Excitement - Great Deals</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}