import {useEffect, useState} from 'react';

import {Swiper, SwiperSlide} from 'swiper/react';

import 'swiper/css';

import {NftOfferService} from '../../../service/common/NftOfferService';
import {NftAssetsService} from '../../../service/common/NftAssetsService';

import {ProductFeatured} from '../../components/Product';

export const Hero = ({iamUser, balances}) => {

  const [assets, setAssets] = useState([]);

  useEffect(() => {
    if (balances?.length) {
      const event = {
        filters: {
          priceOrg: {value: balances.map(item => item.value), matchMode: 'in'},
          fromDate: {value: new Date(), matchMode: 'lte'},
          thruDate: {value: new Date(), matchMode: 'gte'},
          status: {value: 'Open', matchMode: 'equals'}
        },
        rows: 2
      };
      NftOfferService.getList(JSON.stringify(event)).then(({items}) => {
        if (items.length) {
          const event = {
            filters: {
              _id: {value: items.map(item => item.nftAsset._id), matchMode: 'in'}
            }
          };
          NftAssetsService.getList(JSON.stringify(event)).then(data => {
            setAssets(data?.items);
          });
        }
      });
    }
  }, [balances]);

  return (
    <div className="hero-area hero-style-two">
      <Swiper spaceBetween={50} className="hero-slider-two">
        <SwiperSlide className="hero-item position-relative">
          <div className="hero-background-layer" style={{background: 'url(/assets/images/hero-banner2.png)'}}></div>
          <div className="hero-content-wrap">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="hero-content">
                    <h1 className="hero-title">Welcome to car auction Best Platform</h1>
                    <p>Middleton in objection discovery as agreeable. Edward thrown dinin. Nulla sem porta ullamcorper orci, scelerisque ullamcorper vitae.</p>
                    <div className="hero-btns d-flex gap-5 align-items-center">
                      <a href={someValidPath()} className="alpha-btn-md">All Auction</a>
                      <div className="video-pluse">
                        <span></span>
                        <span></span>
                        <span></span>
                        <a href="https://www.youtube.com/watch?v=u31qwQUeGuM" className="video-frame video-icon"><i className="bx bx-play"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 pt-5 pt-lg-0">
                  <div className="row g-4">
                    {assets.map((item, index) => <div key={index} className="col-sm-6">
                      <ProductFeatured iamUser={iamUser} asset={item} fontSize="2.5rem"/>
                    </div>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

const someValidPath = () => {
  return undefined;
}