import {Fragment} from 'react';
import {Link} from 'react-router-dom';

import {Breadcrumb} from '../components/layout/Breadcrumb';
import {Pagination} from '../components/Pagination';

export const Blog = () => {

  return (
    <Fragment>
      <Breadcrumb title="Blog"/>

      <div className="blog-grid-wrapper pt-110 pb-110">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="blog-card blog-style-gamma">
                <div className="thumb">
                  <Link to="/pages/blog-details"><img src="assets/images/blog/bb4.png" alt=""/></Link>
                </div>
                <div className="blog-body">
                  <h5 className="blog-title"><Link to="/pages/blog-details">Dolor lorem turpis orci, nunc suscipit tortor, habitasse et in</Link></h5>
                  <div className="blog-meta">
                    <div className="author">
                      <img src="assets/images/blog/blog-writer1.png" alt=""/>
                    </div>
                    <span className="author">Robart Fox, </span>
                    <span className="date">March 9, 2021</span>
                  </div>
                  <p>Lorem ipsum dolor sit amet, cras non sagittis pellentes que donec, nunc eleifend</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-lg-4 col-md-6 col-sm-6">
              <div className="blog-card blog-style-gamma">
                <div className="thumb">
                  <Link to="/pages/blog-details"><img src="assets/images/blog/bb5.png" alt=""/></Link>
                </div>
                <div className="blog-body">
                  <h5 className="blog-title"><Link to="/pages/blog-details">Magna venenatis, sed sed, amet lec tus, bibendum </Link></h5>
                  <div className="blog-meta">
                    <div className="author">
                      <img src="assets/images/blog/blog-writer1.png" alt=""/>
                    </div>
                    <span className="author">Robart Fox, </span>
                    <span className="date">March 9, 2021</span>
                  </div>
                  <p>Lorem ipsum dolor sit amet, cras non sagittis pellentes que donec, nunc eleifend</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-lg-4 col-md-6 col-sm-6">
              <div className="blog-card blog-style-gamma">
                <div className="thumb">
                  <Link to="/pages/blog-details"><img src="assets/images/blog/bb7.png" alt=""/></Link>
                </div>
                <div className="blog-body">
                  <h5 className="blog-title"><Link to="/pages/blog-details">A brand for a company is like a reputation for a person</Link></h5>
                  <div className="blog-meta">
                    <div className="author">
                      <img src="assets/images/blog/blog-writer1.png" alt=""/>
                    </div>
                    <span className="author">Robart Fox, </span>
                    <span className="date">March 9, 2021</span>
                  </div>
                  <p>Lorem ipsum dolor sit amet, cras non sagittis pellentes que donec, nunc eleifend</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="blog-card blog-style-gamma">
                <div className="thumb">
                  <Link to="/pages/blog-details"><img src="assets/images/blog/bb1.png" alt=""/></Link>
                </div>
                <div className="blog-body">
                  <h5 className="blog-title"><Link to="/pages/blog-details">Hella Kogi Whatever, Small Batch Pickled</Link></h5>
                  <div className="blog-meta">
                    <div className="author">
                      <img src="assets/images/blog/blog-writer1.png" alt=""/>
                    </div>
                    <span className="author">Robart Fox, </span>
                    <span className="date">March 9, 2021</span>
                  </div>
                  <p>Lorem ipsum dolor sit amet, cras non sagittis pellentes que donec, nunc eleifend</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-lg-4 col-md-6 col-sm-6">
              <div className="blog-card blog-style-gamma">
                <div className="thumb">
                  <Link to="/pages/blog-details"><img src="assets/images/blog/bb2.png" alt=""/></Link>
                </div>
                <div className="blog-body">
                  <h5 className="blog-title"><Link to="/pages/blog-details">Winner of auction auction will get gift</Link></h5>
                  <div className="blog-meta">
                    <div className="author">
                      <img src="assets/images/blog/blog-writer1.png" alt=""/>
                    </div>
                    <span className="author">Robart Fox, </span>
                    <span className="date">March 9, 2021</span>
                  </div>
                  <p>Lorem ipsum dolor sit amet, cras non sagittis pellentes que donec, nunc eleifend</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-lg-4 col-md-6 col-sm-6">
              <div className="blog-card blog-style-gamma">
                <div className="thumb">
                  <Link to="/pages/blog-details"><img src="assets/images/blog/bb10.png" alt=""/></Link>
                </div>
                <div className="blog-body">
                  <h5 className="blog-title"><Link to="/pages/blog-details">Amet pulvinar varius phare trasem tristique</Link></h5>
                  <div className="blog-meta">
                    <div className="author">
                      <img src="assets/images/blog/blog-writer1.png" alt=""/>
                    </div>
                    <span className="author">Robart Fox, </span>
                    <span className="date">March 9, 2021</span>
                  </div>
                  <p>Lorem ipsum dolor sit amet, cras non sagittis pellentes que donec, nunc eleifend</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="blog-card blog-style-gamma">
                <div className="thumb">
                  <Link to="/pages/blog-details"><img src="assets/images/blog/bb8.png" alt=""/></Link>
                </div>
                <div className="blog-body">
                  <h5 className="blog-title"><Link to="/pages/blog-details">Dolor lorem turpis orci, nunc suscipit tortor, habitasse et in</Link></h5>
                  <div className="blog-meta">
                    <div className="author">
                      <img src="assets/images/blog/blog-writer1.png" alt=""/>
                    </div>
                    <span className="author">Robart Fox, </span>
                    <span className="date">March 9, 2021</span>
                  </div>
                  <p>Lorem ipsum dolor sit amet, cras non sagittis pellentes que donec, nunc eleifend</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-lg-4 col-md-6 col-sm-6">
              <div className="blog-card blog-style-gamma">
                <div className="thumb">
                  <Link to="/pages/blog-details"><img src="assets/images/blog/bb6.png" alt=""/></Link>
                </div>
                <div className="blog-body">
                  <h5 className="blog-title"><Link to="/pages/blog-details">Magna venenatis, sed sed, amet lec tus, bibendum </Link></h5>
                  <div className="blog-meta">
                    <div className="author">
                      <img src="assets/images/blog/blog-writer1.png" alt=""/>
                    </div>
                    <span className="author">Robart Fox, </span>
                    <span className="date">March 9, 2021</span>
                  </div>
                  <p>Lorem ipsum dolor sit amet, cras non sagittis pellentes que donec, nunc eleifend</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-lg-4 col-md-6 col-sm-6">
              <div className="blog-card blog-style-gamma">
                <div className="thumb">
                  <Link to="/pages/blog-details"><img src="assets/images/blog/blog-grid33.png" alt=""/></Link>
                </div>
                <div className="blog-body">
                  <h5 className="blog-title"><Link to="/pages/blog-details">A brand for a company is like a reputation for a person</Link></h5>
                  <div className="blog-meta">
                    <div className="author">
                      <img src="assets/images/blog/blog-writer1.png" alt=""/>
                    </div>
                    <span className="author">Robart Fox, </span>
                    <span className="date">March 9, 2021</span>
                  </div>
                  <p>Lorem ipsum dolor sit amet, cras non sagittis pellentes que donec, nunc eleifend</p>
                </div>
              </div>
            </div>
          </div>

          <Pagination/>
        </div>
      </div>
    </Fragment>
  );
}