import {Route, Routes} from 'react-router-dom';

import {About} from './About';
import {Contact} from './Contact';
import {Blog} from './Blog';
import {BlogStandard} from './BlogStandard';
import {BlogDetails} from './BlogDetails';
import {Login} from './Login';

export const Pages = () => {

  return (
    <Routes>
      <Route path="about" element={<About/>}/>
      <Route path="contact" element={<Contact/>}/>
      <Route path="blog" element={<Blog/>}/>
      <Route path="blog-standard" element={<BlogStandard/>}/>
      <Route path="blog-details" element={<BlogDetails/>}/>
      <Route path="login" element={<Login/>}/>
    </Routes>
  );
}