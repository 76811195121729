import {Fragment} from 'react';

import {TabView, TabPanel} from 'primereact/tabview';

import {Breadcrumb} from '../components/layout/Breadcrumb';

import {Balances} from './Balances';
import {Transactions} from './Transactions';

export const Holdings = ({iamUser, balances}) => {

  return (
    <Fragment>
      <Breadcrumb title="Holdings"/>

      <div className="auction-wrapper pt-50 pb-110">
        <div className="container">
          <TabView>
            <TabPanel header="Balances">
              <h5>BALANCES</h5>

              <Balances iamUser={iamUser} balances={balances}/>
            </TabPanel>
            <TabPanel header="Deposits">
              <h5>DEPOSIT HISTORY</h5>

              <Transactions iamUser={iamUser} type="Deposit"/>
            </TabPanel>
            <TabPanel header="Withdrawals">
              <h5>WITHDRAWAL HISTORY</h5>

              <Transactions iamUser={iamUser} type="Withdrawal"/>
            </TabPanel>
          </TabView>
        </div>
      </div>
    </Fragment>
  );
}