import {Fragment} from 'react';

import {Breadcrumb} from '../components/layout/Breadcrumb';

export const Contact = () => {

  return (
    <Fragment>
      <Breadcrumb title="Contact Us"/>

      <div className="contact-wrapper  mt-110">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="page-header mx-auto text-center">
                <h3>Any Asking To The Officers?</h3>
                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5">
              <div className="contact-box-wrap">
                <h4>Contact Us</h4>
                <div className="single-contact-box">
                  <h6>Let’s Talk</h6>
                  <a href="mailto:info@whychain.net" className="mail">info@whychain.net</a> <a href="mailto:support@whychain.net" className="mail">support@whychain.net</a>
                  <a href="tel:+84 776 322 555" className="phone">+84 776 322 555</a>
                </div>
                <div className="single-contact-box">
                  <h6>Loacation.</h6>
                  <a href="/#" className="location">123 Quang Trung, Ha Dong, Ha Noi, Vietnam</a>
                </div>
                <div className="single-contact-box">
                  <h6>Social Site Link</h6>
                  <a href="/#" className="weblink">Facebook: https://www.facebook.com</a>
                  <a href="/#" className="weblink">Twitter: https://www.twitter.com</a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 mt-5 mt-lg-0">
              <div className="contact-form">
                <h4>Questions or Comments?</h4>
                <form action="/#" id="contact_form" method="post">
                  <div className="contact-input-group">
                    <label htmlFor="first-Name">Full Name</label>
                    <input type="text" id="first-Name" placeholder="Full Name"/>
                  </div>
                  <div className="contact-input-group">
                    <label htmlFor="email">Email</label>
                    <input type="text" id="email" placeholder="Enter Your Email"/>
                  </div>
                  <div className="contact-input-group">
                    <label htmlFor="email">Message</label>
                    <textarea id="massege" cols={30} rows={7} placeholder="Type your Message"></textarea>
                  </div>
                  <div className="contact-input-group mt-0">
                    <button type="submit" className="submit-btn">Submit Comment</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}