
export const Describe = ({asset}) => {

  return (
    <div className="item-describe-area mt-100">
      <ul className="nav nav-pills d-flex justify-content-center gap-sm-4 gap-1" id="pills-tab" role="tablist">
        <li className="nav-item" role="presentation">
          <button className="nav-link active describe-btn" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Description</button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link describe-btn" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Location</button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link describe-btn" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">About Video</button>
        </li>
      </ul>
      <div className="tab-content" id="pills-tabContent">
        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
          <div className="describe-content">
            <p className="mb-15">{asset?.long_description}</p>
          </div>
        </div>
        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
          <div className="map-area">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3649.73467103074!2d90.36181521536365!3d23.828032291722206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c13a21730b43%3A0xb14a9c52d01c00d9!2sMirpur-12%20Bus%20Stand!5e0!3m2!1sen!2sbd!4v1640171021567!5m2!1sen!2sbd" title="vdo" loading="lazy"></iframe>
          </div>
        </div>
        <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
          <div className="about-video">
            <div className="wrapper">
              <div className="video-pluse">
                <span></span>
                <span></span>
                <span></span>
                <a href="https://www.youtube.com/watch?v=u31qwQUeGuM" className="video-frame video-icon"><i className="bx bx-play"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="details-social-area">
        <h5 className="wdget-title">Share Now</h5>
        <ul>
          <li><a href="https://www.facebook.com"><i className="bx bxl-facebook"></i></a></li>
          <li><a href="https://twitter.com"><i className="bx bxl-twitter"></i></a></li>
          <li><a href="https://www.pinterest.com"><i className="bx bxl-pinterest-alt"></i></a></li>
          <li><a href="https://www.instagram.com"><i className="bx bxl-instagram"></i></a></li>
        </ul>
      </div>
    </div>
  );
}