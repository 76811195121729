import axios from './axios';

import {baseURL} from '../config';

export abstract class Service {

  static entity: string;

  static baseURL = baseURL;

  static axios = axios;

}
