import axios from 'axios';

import {LoadingService} from '../AppLoading';

import {baseURL} from '../config';

const _axios = axios.create({
  baseURL
});

_axios.interceptors.request.use(function (config) {
  LoadingService.start();
  return config;
}, function (error) {
  return Promise.reject(error);
});
_axios.interceptors.response.use((response) => {
  LoadingService.stop();
  return response;
}, (error) => {
  LoadingService.stop();
  if (error && error.response && error.response.status === 401) {
    window.location.hash = '/login';
  } else if (error.response && error.response.data && error.response.data.message) {
    let message;
    if (typeof error.response.data.message === 'string') {
      message = error.response.data.message;
    } else if (error.response.data.message.message) {
      message = error.response.data.message.message;
    }
    alert(message);
  }
  return Promise.reject(error);
});

export default _axios;
