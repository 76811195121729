import {Fragment, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import {ConfirmDialogService, FormatDisplay, useDialogCrup} from '@iamsoftware/react-hooks';

import {NftAssetsService} from '../../service/common/NftAssetsService';
import {NftOfferService} from '../../service/common/NftOfferService';
import {NftAskService} from '../../service/common/NftAskService';
import {TransactionService} from '../../service/common/TransactionService';

interface ProductProps {
  iamUser?: any
  balances?: Array<any>
  asset: any
  fontSize?: string

  doUpdate?(data?: any): void
}

export const useProductData = ({asset_id}) => {

  const [dataState, setDataState] = useState(0);

  const [offer, setOffer] = useState(null);
  const [currentBid, setCurrentBid] = useState(0);

  useEffect(() => {
    if (asset_id) {
      loadData();
    }
  }, [asset_id, dataState]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadCurrentBid = _offer => {
    if (_offer?._id) {
      let _currentBid = _offer.price;

      const event = {
        filters: {
          nftOffer: {value: _offer._id, matchMode: 'ObjectId'},
          status: {value: 'Completed', matchMode: 'notEquals'}
        },
        rows: 1,
        sortField: 'amount',
        sortOrder: -1
      };

      NftAskService.getList(JSON.stringify(event)).then(({items}) => {
        if (items?.length) {
          if (items[0].amount) {
            _currentBid = items[0].amount;
          }
        }
        setCurrentBid(_currentBid);
      });
    }
  }

  const loadData = () => {
    if (asset_id) {
      const event = {
        filters: {
          nftAsset: {value: asset_id, matchMode: 'ObjectId'},
          status: {value: ['Completed', 'Cancelled'], matchMode: 'nin'}
        }
      };
      NftOfferService.getList(JSON.stringify(event)).then(data => {
        if (data?.items && data.items?.length) {
          setOffer(data.items[0]);
          loadCurrentBid(data.items[0]);
        } else {
          setOffer(null);
        }
      });
    }
  }

  return {offer, currentBid, setProductDataState: setDataState};
}

export const ProductBetaMd = (props: ProductProps) => {

  const {iamUser, asset, fontSize} = props;

  const {offer, currentBid, setProductDataState} = useProductData({asset_id: asset?._id});

  return (
    <div className="product-card product-beta-md">
      <Link to={`/accounts/${asset._id}`}>
        <div className="product-header">
          <h5 className="product-title">{asset.description}</h5>
          <div className="row g-4">
            <div className="col-6">
              {iamUser._id === asset.user?._id && <p>Owned by <b>you</b></p>}
              {iamUser._id !== asset.user?._id && <p>Owned by <b>{asset.user.name}</b></p>}
            </div>
            <div className="col-6">
              {offer?._id && <h4 className="price">{offer.priceOrg} {FormatDisplay.number(offer.price)}</h4>}
            </div>
          </div>
        </div>
        <div className="product">
          {asset?.image && <img src={asset?.image} alt=""/>}
          {!asset?.image && <img src={'/assets/images/blank-bg.jpg'} alt=""/>}
          <div className="id"><span>ID {asset.key?.substring(9)}</span></div>
          <div className="name" style={{fontSize: fontSize}}><span>{asset.name}</span></div>
        </div>
      </Link>
      <div className="product-disc">
        <div className="product-acution" style={{height: '51px'}}>
          <div className="cv">
            {offer?._id && <Fragment>
							<span>current Bid</span>
							<h6>{offer.priceOrg} {FormatDisplay.number(currentBid)}</h6>
						</Fragment>}
          </div>

          <div className="iam-countdown">
            {offer?._id && <Fragment>
              {new Date(offer.fromDate) < new Date() && <Fragment>
								<span style={{display: 'none'}}>{offer.thruDate}</span>
								<h6>0</h6>
								<span>Waiting For Bid</span>
							</Fragment>}
              {new Date(offer.fromDate) > new Date() && <Fragment>
								<span style={{display: 'none'}}>{offer.fromDate}</span>
								<h6>0</h6>
								<span>Coming Soon</span>
							</Fragment>}
						</Fragment>}
          </div>
        </div>
        {iamUser._id === asset.user?._id && <Ours {...props as any} offer={offer} setProductDataState={setProductDataState}/>}
        {iamUser._id !== asset.user?._id && <Theirs {...props as any} offer={offer} setProductDataState={setProductDataState}/>}
      </div>
    </div>
  );
}

const Ours = ({iamUser, balances, asset, offer, doUpdate, setProductDataState}) => {

  const doCreateItem = item => {

    TransactionService.create({
      user: asset._id,
      type: 'NftActivity',
      description: `List by ${iamUser.name}`,
      symbol: item.priceOrg,
      quantity: item.price,
      block_hash: ''
    }).then();

    item.user = iamUser._id;
    item.nftAsset = asset._id;
    item.status = 'Open';
    return NftOfferService.create(item);
  }

  const {render: renderDialogCrup, create, update} = useDialogCrup({
    header: '!List for sale',
    dataKey: '_id',
    width: '40rem',
    fields: [
      {field: 'price', header: 'Price', required: true, type: 'InputNumber', className: 'md:col-6'},
      {field: 'priceOrg', header: 'Currency', required: true, type: 'Dropdown', DropdownProps: {options: balances}, className: 'md:col-6'},
      {field: 'fromDate', header: 'Starting', required: true, type: 'Calendar', CalendarProps: {showTime: true}, className: 'md:col-6'},
      {field: 'thruDate', header: 'Ending', required: true, type: 'Calendar', CalendarProps: {showTime: true}, className: 'md:col-6'}
    ],
    createItem(item: any): Promise<any> {
      return doCreateItem(item).then(() => {
        return NftAssetsService.update(asset._id, {status: 'Listing'});
      });
    },
    updateItem(id: string, item: any): Promise<any> {
      return NftOfferService.update(id, {status: 'Cancelled'}).then(() => {
        return doCreateItem(item);
      });
    },
    reloadLazyData: () => {
      setProductDataState(Date.now());
    }
  });

  const doUpdateListing = () => {
    if (offer.fromDate) {
      offer.fromDate = new Date(offer.fromDate)
    }
    if (offer.thruDate) {
      offer.thruDate = new Date(offer.thruDate)
    }
    update(offer);
  }

  const doCancelListing = () => {
    ConfirmDialogService.confirm('Cancel listing', 'Cancel this listing?', () => {
      NftOfferService.update(offer._id, {status: 'Cancelled'}).then(() => {
        setProductDataState(Date.now());
      });
    });
  }

  return (
    <Fragment>
      {offer?._id && <Fragment>
				<a href={someValidPath()} className="bid-btn mr-2" onClick={doUpdateListing}>
					Edit listing
				</a>
				<a href={someValidPath()} className="bid-btn" onClick={doCancelListing}>
					Cancel listing
				</a>
			</Fragment>}
      {!offer?._id && <Fragment>
				<a href={someValidPath()} className="bid-btn mr-2" onClick={() => doUpdate(asset)}>
					Edit
				</a>
				<a href={someValidPath()} className="bid-btn" onClick={() => create({fromDate: new Date()})}>
					Sell
				</a>
			</Fragment>}

      {renderDialogCrup()}
    </Fragment>
  );
}
const Theirs = ({asset}) => {
  return <Link to={`/accounts/${asset._id}`} className="bid-btn">Bid Now</Link>
}

export const ProductBetaSm = (props: ProductProps) => {

  const {asset, fontSize} = props;

  const {offer, currentBid} = useProductData({asset_id: asset?._id});

  return (
    <div className="product-card product-beta-sm">
      <div className="product">
        {asset?.image && <img src={asset?.image} alt=""/>}
        {!asset?.image && <img src={'/assets/images/blank-bg.jpg'} alt=""/>}
        <div className="id"><span>ID {asset.key?.substring(9)}</span></div>
        <div className="name" style={{fontSize}}><span>{asset?.name}</span></div>
      </div>
      <div className="product-disc">
        <h6 className="product-title">
          <Link to={`/accounts/${asset._id}`}>{asset?.description}</Link>
        </h6>
        <div className="product-acution">
          <div className="cv">
            <span>current Bid</span>
            <h6>{offer?.priceOrg} {FormatDisplay.number(currentBid)}</h6>
          </div>
          <div className="iam-countdown">
            {offer?._id && <Fragment>
              {new Date(offer.fromDate) < new Date() && <Fragment>
								<span style={{display: 'none'}}>{offer.thruDate}</span>
								<h6>0</h6>
								<span>Waiting For Bid</span>
							</Fragment>}
              {new Date(offer.fromDate) > new Date() && <Fragment>
								<span style={{display: 'none'}}>{offer.fromDate}</span>
								<h6>0</h6>
								<span>Coming Soon</span>
							</Fragment>}
						</Fragment>}
          </div>
        </div>
      </div>
    </div>
  );
}

export const ProductFeatured = (props: ProductProps) => {

  const {asset, fontSize} = props;

  const {offer, currentBid} = useProductData({asset_id: asset?._id});

  return (
    <div className="product-card featured-product-card">
      <div className="product">
        {asset?.image && <img src={asset?.image} alt=""/>}
        {!asset?.image && <img src={'/assets/images/blank-bg.jpg'} alt=""/>}
        <div className="id"><span>ID {asset.key?.substring(9)}</span></div>
        <div className="name" style={{fontSize}}><span>{asset?.name}</span></div>
      </div>
      <div className="product-disc">
        <h6 className="product-title"><a href={someValidPath()} className="">{asset?.description}</a></h6>
        <div className="product-auction">
          <div className="price">
            <span>From</span>
            <h6>{offer?.priceOrg} {FormatDisplay.number(currentBid)}</h6>
          </div>
          <div className="iam-countdown countdown">
            {offer?._id && <Fragment>
              {new Date(offer.fromDate) < new Date() && <Fragment>
								<span style={{display: 'none'}}>{offer.thruDate}</span>
								<h6>0</h6>
								<span>Waiting For Bid</span>
							</Fragment>}
              {new Date(offer.fromDate) > new Date() && <Fragment>
								<span style={{display: 'none'}}>{offer.fromDate}</span>
								<h6>0</h6>
								<span>Coming Soon</span>
							</Fragment>}
						</Fragment>}
          </div>
        </div>
        <Link to={`/accounts/${asset._id}`} className="bid-btn">Bid Now</Link>
      </div>
    </div>
  );
}

const someValidPath = () => {
  return undefined;
}