import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper';

import 'swiper/css/navigation';

export const TopWinner = () => {

  const someValidPath = () => {
    return undefined;
  }

  return (
    <div className="top-winner-area top-winnner-style-two mt-110">
      <div className="container position-relative">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-header-two">
              <h3>Top winner</h3>
            </div>
          </div>
        </div>

        <Swiper spaceBetween={50} slidesPerView={6} navigation={true} modules={[Navigation]} className="winner-slider">
          <SwiperSlide>
            <div className="winner-gamma">
              <div className="winner-image">
                <img src="assets/images/winner/winner-b1.png" alt=""/>
              </div>
              <div className="winner-info">
                <h5 className="winner-name"><a href={someValidPath()}>Esther Howard</a></h5>
                <p className="id">ID: 0195608</p>
                <a href={someValidPath()} className="meta"><i className="bi bi-bag-plus"></i></a>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="winner-gamma">
              <div className="winner-image">
                <img src="assets/images/winner/winner-b2.png" alt=""/>
              </div>
              <div className="winner-info">
                <h5 className="winner-name"><a href={someValidPath()}>Eleanor Pena</a></h5>
                <p className="id">ID: 0195608</p>
                <a href={someValidPath()} className="meta"><i className="bi bi-bag-plus"></i></a>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="winner-gamma">
              <div className="winner-image">
                <img src="assets/images/winner/winner-b3.png" alt=""/>
              </div>
              <div className="winner-info">
                <h5 className="winner-name"><a href={someValidPath()}>Albert Flores</a></h5>
                <p className="id">ID: 0195608</p>
                <a href={someValidPath()} className="meta"><i className="bi bi-bag-plus"></i></a>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="winner-gamma">
              <div className="winner-image">
                <img src="assets/images/winner/winner-b4.png" alt=""/>
              </div>
              <div className="winner-info">
                <h5 className="winner-name"><a href={someValidPath()}>Robert Fox</a></h5>
                <p className="id">ID: 0195608</p>
                <a href={someValidPath()} className="meta"><i className="bi bi-bag-plus"></i></a>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="winner-gamma">
              <div className="winner-image">
                <img src="assets/images/winner/winner-b5.png" alt=""/>
              </div>
              <div className="winner-info">
                <h5 className="winner-name"><a href={someValidPath()}>Jane Cooper</a></h5>
                <p className="id">ID: 0195608</p>
                <a href={someValidPath()} className="meta"><i className="bi bi-bag-plus"></i></a>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="winner-gamma">
              <div className="winner-image">
                <img src="assets/images/winner/winner-b6.png" alt=""/>
              </div>
              <div className="winner-info">
                <h5 className="winner-name"><a href={someValidPath()}>Jenny Wilson</a></h5>
                <p className="id">ID: 0195608</p>
                <a href={someValidPath()} className="meta"><i className="bi bi-bag-plus"></i></a>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

      </div>
    </div>
  );
}